import axios from 'axios';
import Swal from 'sweetalert2'
import { api } from '../../domainConfig';

/* eslint no-shadow: ["error", { "allow": ["state"] }] */

const domain = api.userManagementDomain;
const domain1 = api.clientMasterDataDomain;
const domain3 = api.storeDataDomain


const state = {
  status: false,
  users: [],
  inactiveusers: [],
  fileteredActiveUsers: [],
  filteredInactiveUsers: [],
  userRole: [],
  currentPage: 1,
  totalItems: 0,
  totalItems_2: 0,
  totalItemsInactive: 0,
  totalPagesUsers: 0,
  notifications: [],
  notificationCount: 0,
  notificationCountFromBack: 0,
  totalItemsStore: 0,
  totalPagesStore: 0,
  totalItemsForUsers: 0,
  totalPagesForUsers: 0,
  payment: [],
  employeePaymentPagination: [],
  userPDF: [],
  feedback: [],
  employeeFeedbackPagination: [],
  feedbackForDashboard: [],
  monthlyOverviewPaymentPagination: [],
  fullUserById: {},
  employeeBirthdays: [],
  usersActiveInactive: [],
  dashboardSalary: [],
  employeeTotalNumber: [],
  employeesOnVacation: [],
  storeNames: [],
  allStoresByPagination: [],
  storesDropdown: [],
  userPaginationWorkplace: [],
  storesByNameLike: [],
  resetStore: [],
};

const getters = {
  getUsers: (state) => {
    return state.users;
  },
  getMonthlyOverviewPaymentPagination: (state) => {
    return state.monthlyOverviewPaymentPagination
  },
  getTotalPagesUsers: (state) => {
    return state.totalPagesUsers
  },
  getStoresDropdown: (state) => {
    return state.storesDropdown
  },
  getPayment: (state) => {
    return state.payment
  },
  getUserRole: (state) => {
    return state.userRole
  },
  getEmployeeBirthdays: (state) => {
    return state.employeeBirthdays
  },
  getFullUsersById: (state) => {
    return state.fullUserById;
  },
  getInactiveUsers: (state) => {
    return state.inactiveusers;
  },

  getCurrentPageForUsers: (state) => {
    return state.currentPage;
  },

  getFilteredActiveUsers: (state) => {
    return state.fileteredActiveUsers;
  },
  getFilteredInactiveUsers: (state) => {
    return state.filteredInactiveUsers;
  },

  getTotalItemsForUsers: (state) => {
    return state.totalItems;
  },
  getTotalItemsForUsers_2: (state) => {
    return state.totalItems_2;
  },
  getTotalItemsForInActiveUsers: (state) => {
    return state.totalItemsInactive;
  },
  getNotifications: (state) => {
    // console.log('wer', state.notifications)
    return state.notifications
  },
  getNotificationCount(state) {
    return state.notificationCount;
  },
  getNotificationCountFromBack(state) {
    return state.notificationCountFromBack
  },
  getEmployeePaymentPagination: (state) => {
    return state.employeePaymentPagination
  },
  getUserPDF: (state) => {
    return state.userPDF
  },
  getFeedback: (state) => {
    return state.feedback
  },
  getEmployeeFeedbackPagination: (state) => {
    return state.employeeFeedbackPagination
  },
  getFeedbackForDashboard: (state) => {
    return state.feedbackForDashboard
  },
  getUsersActiveInactive: (state) => {
    return state.usersActiveInactive
  },
  getDashboardSalary: (state) => {
    return state.dashboardSalary
  },
  getEmployeeTotalNumber: (state) => {
    return state.employeeTotalNumber
  },
  getEmployeesOnVacation: (state) => {
    return state.employeesOnVacation
  },
  getStoreNameByLeadership: (state) => {
    return state.storeNames
  },
  getAllStores: (state) => {
    return state.allStoresByPagination
  },
  getAllStoresTotalItems: (state) => {
    return state.totalItemsStore
  },
  getAllStoresTotalPages: (state) => {
    return state.totalPagesStore
  },
  getUsersPaginationWorkplace: (state) => {
    return state.userPaginationWorkplace
  },
  getUsersPaginationWorkplaceTotalItems: (state) => {
    return state.totalItemsForUsers
  },
  getUsersPaginationWorkplaceTotalPages: (state) => {
    return state.totalPagesForUsers
  },
  getStoresByNameLike: (state) => {
    return state.storesByNameLike
  },
  getResetStore: (state) => {
    return state.resetLocation;
  },
};

const mutations = {
  SET_STATUS(state, payload) {
    state.status = payload;
  },
  SET_MONTHLY_OVERVIEW_PAYMENT_PAGINATION(state, data) {
    state.monthlyOverviewPaymentPagination = data
  },
  SET_STORES_DROPDOWN(state, latest) {
    state.storesDropdown = latest
  },
  SET_PAYMENT(state, data) {
    state.payment = data
  },
  SET_EMPLOYEE_PAYMENT_PAGINATION(state, data) {
    state.employeePaymentPagination = data
  },
  SET_CURRENTPAGE(state, payload) {
    state.currentPage = payload;
  },
  SET_TOTAL_PAGES(state, latest) {
    state.totalPagesUsers = latest
  },
  SET_CURRENTPAGE_2(state, payload) {
    state.currentPage = payload;
  },
  SET_USERS(state, latest) {
    state.users = latest;
  },
  SET_USER_ROLE(state, payload) {
    state.userRole = payload
  },
  SET_INACTIVEUSERS(state, latest) {
    state.inactiveusers = latest;
  },
  SET_EMPLOYEE_BIRTHDAYS(state, latest) {
    state.employeeBirthdays = latest
  },
  SET_FULL_USERS_BY_ID(state, latest) {
    state.fullUserById = latest
  },
  SET_FILTERED_ACTIVE_USERS(state, latest) {
    state.fileteredActiveUsers = latest;
  },
  SET_FILTERED_INACTIVEUSERS(state, latest) {
    state.filteredInactiveUsers = latest;
  },
  SET_TOTALITEMS_USERS(state, latest) {
    state.totalItems = latest;
  },
  SET_TOTALITEMS_USERS_2(state, latest) {
    state.totalItems_2 = latest;
  },
  SET_TOTALITEMS_INACTIVEUSERS(state, latest) {
    state.totalItemsInactive = latest;
  },
  SET_NOTIFICATIONS(state, latest) {
    state.notifications = latest
  },
  INCREMENT_NOTIFICATION_COUNT(state, latest) {
    state.notificationCount = latest;
  },
  RESET_NOTIFICATION_COUNT(state) {
    state.notificationCount = 0;
  },
  SET_NOTIFICATION_COUNT_FROM_BACKEND(state, latest) {
    state.notificationCountFromBack = latest
  },
  RESET_NOTIFICATION_COUNT_FROM_BACKEND(state, latest) {
    state.notificationCountFromBack = latest
  },
  SET_USER_PDF(state, latest) {
    state.userPDF = latest
  },
  SET_FEEDBACK(state, data) {
    state.feedback = data
  },
  SET_EMPLOYEE_FEEDBACK_PAGINATION(state, data) {
    state.employeeFeedbackPagination = data
  },
  SET_FEEDBACK_FOR_DASHBOARD(state, data) {
    const res = data.sort((a, b) => {
      return b.rangingValue - a.rangingValue
    })
    state.feedbackForDashboard = res
  },
  SET_USERS_ACTIVE_INACTIVE(state, latest) {
    state.usersActiveInactive = latest
  },
  SET_DASHBOARD_SALARY(state, latest) {
    state.dashboardSalary = latest
  },
  SET_EMPLOYEE_TOTAL_NUMBER(state, latest) {
    state.employeeTotalNumber = latest
  },
  SET_EMPLOYEE_ON_VACATION(state, latest) {
    state.employeesOnVacation = latest
  },
  SET_STORE_NAMES(state, latest) {
    state.storeNames = latest
  },
  SET_STORES_BY_PAGINATION(state, latest) {
    state.allStoresByPagination = latest
  },
  SET_STORES_BY_PAGINATION_TOTALITEMS(state, latest) {
    state.totalItemsStore = latest
  },
  SET_STORES_BY_PAGINATION_TOTALPAGES(state, latest) {
    state.totalPagesStore = latest
  },
  SET_USERS_WORKPLACE(state, latest) {
    state.userPaginationWorkplace = latest
  },
  SET_USERS_WORKPLACE_TOTALITEMS(state, latest) {
    state.totalItemsForUsers = latest
  },
  SET_USERS_WORKPLACE_TOTALPAGES(state, latest) {
    state.totalPagesForUsers = latest
  },
  SET_STORES_BY_NAME_LIKE(state, latest) {
    state.storesByNameLike = latest
  },
  SET_RESET_STORE(state, latest) {
    state.resetLocation = latest
  },
};
const actions = {
  async getUsersPagination({ commit }, { pageNumber, pageSize, fullName }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/getUsersPagination`, {
        params: {
          pageNumber,
          pageSize,
          fullName,
        },
      });
      commit('SET_USERS', response.data.items);
      commit('SET_CURRENTPAGE', response.data.currentPage);
      commit('SET_TOTALITEMS_USERS', response.data.totalItems);
      commit('SET_TOTAL_PAGES', response.data.totalPages);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  async storeByNameLike({ commit }, { storeName }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain3}/storeByNameLike?storeName=${storeName}`, {
      });
      commit('SET_STORES_BY_NAME_LIKE', response.data);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  async resetStores({ commit }) {
    commit('SET_RESET_STORE', [])
  },
  async usersWorkPlacePagination({ commit }, {
    pageNumber, pageSize, status, userId,
  }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/users_pagination_wokplace`, {
        params: {
          pageNumber,
          pageSize,
          status,
          userId,
        },
      });
      commit('SET_USERS_WORKPLACE', response.data.items);
      commit('SET_USERS_WORKPLACE_TOTALITEMS', response.data.totalItems);
      commit('SET_USERS_WORKPLACE_TOTALPAGES', response.data.totalPages);
    } catch (error) {
      commit('SET_IS_LOADING', true);
    }
    commit('SET_IS_LOADING', true);
  },
  async getStoreNames({ commit }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain3}/storeNames_by_leadership`);
      commit('SET_STORE_NAMES', response.data);
    } catch (error) {
      commit('SET_IS_LOADING', true);
    }
    commit('SET_IS_LOADING', true);
  },
  async storesByPagination({ commit }, { pageNumber, pageSize, storeName }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain3}/extendedStoresByPagination`, {
        params: {
          pageNumber,
          pageSize,
          storeName,
        },
      });
      commit('SET_STORES_BY_PAGINATION', response.data.items);
      commit('SET_STORES_BY_PAGINATION_TOTALITEMS', response.data.totalItems);
      commit('SET_STORES_BY_PAGINATION_TOTALPAGES', response.data.totalPages);
    } catch (error) {
      commit('SET_IS_LOADING', true);
    }
    commit('SET_IS_LOADING', true);
  },
  async addStore({ commit }, { object, successCallback }) {
    // commit('SET_IS_LOADING', false);
    try {
      await axios.post(`${domain3}/addStore`, object, {
        headers: { 'Content-Type': 'application/json' },
      });
      commit('SET_STATUS', true);
      Swal.fire({
        icon: 'success',
        title: 'Store created successfully',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2500,
      });
      successCallback();
    } catch (error) {
      // Handle error response
      // Show error message using SweetAlert if needed
      if (error.response.status === 400) {
        Swal.fire({
          icon: 'error',
          title: 'Invalid store data. Please try again.',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
      } else if (error.response.status === 500) {
        Swal.fire({
          icon: 'error',
          title: 'An error occurred while adding a store.',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
      }
    }
    // commit('SET_IS_LOADING', true);
  },
  async assignEmployeeToStore({ commit }, { object, successCallback }) {
    // commit('SET_IS_LOADING', false);
    try {
      await axios.post(`${domain3}/assignEmployeeToStore`, object, {
        headers: { 'Content-Type': 'application/json' },
      });
      commit('SET_STATUS', true);
      Swal.fire({
        icon: 'success',
        title: 'Employee assigned to store successfully',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2500,
      });
      successCallback();
    } catch (error) {
      // Handle error response
      // Show error message using SweetAlert if needed
      if (error.response.status === 400) {
        Swal.fire({
          icon: 'error',
          title: 'Invalid employee data. Please try again.',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
      } else if (error.response.status === 500) {
        Swal.fire({
          icon: 'error',
          title: 'An error occurred while assigning employee to store.',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
      }
    }
    // commit('SET_IS_LOADING', true);
  },
  async editStoreOfEmployee({ commit }, { object, successCallback }) {
    commit('SET_IS_LOADING', false);
    try {
      await axios.put(`${domain3}/updateStoreOfEmployee`, object);
      commit('SET_STATUS', true);
      Swal.fire({
        icon: 'success',
        title: 'Store of employee is edited successfully',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2500,
      });
    } catch (error) {
      // Handle error response
      // Show error message using SweetAlert if needed
      Swal.fire({
        icon: 'error',
        title: 'An error occurred while editing the store of employee.',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2500,
      });
    }
    commit('SET_IS_LOADING', true);
    successCallback();
  },
  async activeInactiveUsers({ commit }, {
    pageNumber, pageSize, status, userId,
  }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/active_inactive_users_pagination`, {
        params: {
          pageNumber,
          pageSize,
          status,
          userId,
        },
      });
      commit('SET_USERS_ACTIVE_INACTIVE', response.data.items);
      commit('SET_CURRENTPAGE', response.data.currentPage);
      commit('SET_TOTALITEMS_USERS', response.data.totalItems);
      commit('SET_TOTAL_PAGES', response.data.totalPages);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  async dashboardSalarySum({ commit }, { year, month }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/dashboard_sum_salary`, {
        params: {
          year,
          month,
        },
      });
      commit('SET_DASHBOARD_SALARY', response.data)
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  async employeeTotalNumberDashboard({ commit }, { status }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/employees_count`, {
        params: {
          status,
        },
      });
      commit('SET_EMPLOYEE_TOTAL_NUMBER', response.data)
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  async employeesOnVacationByDate({ commit }, { date }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/employees_on_vacation_by_date`, {
        params: {
          date,
        },
      });
      commit('SET_EMPLOYEE_ON_VACATION', response.data);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  async employeeBirthdays({ commit }, { startDate, endDate }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/upcomingUsersBirthdayDay`, {
        params: {
          startDate,
          endDate,
        },
      });
      commit('SET_EMPLOYEE_BIRTHDAYS', response.data)
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  async loadEmployeeFeedbackPagination({ commit }, {
    firstName, lastName, pageNumber, pageSize,
  }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/employeeFeedbackPagination`, {
        params: {
          firstName, lastName, pageNumber, pageSize,
        },
      });
      commit('SET_EMPLOYEE_FEEDBACK_PAGINATION', response.data.items);
      commit('SET_CURRENTPAGE', response.data.currentPage);
      commit('SET_TOTALITEMS_USERS', response.data.totalItems);
    } catch (error) {
      commit('SET_IS_LOADING', true);
    }
    commit('SET_IS_LOADING', true);
  },
  async loadFeedback({ commit }, {
    userId, year,
  }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/feedback`, {
        params: {
          userId, year,
        },
      });
      commit('SET_FEEDBACK', response.data);
    } catch (error) {
      commit('SET_IS_LOADING', true);
    }
    commit('SET_IS_LOADING', true);
  },
  async loadFeedbackForDashboard({ commit }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/feedbackForDashboard`);
      commit('SET_FEEDBACK_FOR_DASHBOARD', response.data);
    } catch (error) {
      commit('SET_IS_LOADING', true);
    }
    commit('SET_IS_LOADING', true);
  },
  async add_feedback({ commit }, { object, successCallback }) {
    commit('SET_IS_LOADING', false);
    try {
      await axios.post(`${domain}/add_feedback`, object, {
        headers: { 'Content-Type': 'application/json' },
      });
      commit('SET_STATUS', true);
      Swal.fire({
        icon: 'success',
        title: 'User`s feedback added successfully',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2500,
      });
      successCallback();
    } catch (error) {
      // Handle error response
      // Show error message using SweetAlert if needed
      if (error.response.status === 400) {
        Swal.fire({
          icon: 'error',
          title: 'Invalid user data. Please try again.',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
      } else if (error.response.status === 500) {
        Swal.fire({
          icon: 'error',
          title: 'An error occurred while adding the user.',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
      }
    }
    commit('SET_IS_LOADING', true);
  },
  async addRoleToLead({ commit }, { successCallback, clientId, ownerUserId }) {
    commit('SET_IS_LOADING', false);
    try {
      await axios.post(`${domain1}/set_owner_userId?clientId=${clientId}&ownerUserId=${ownerUserId}`);
      commit('SET_STATUS', true);
      successCallback();
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  async addImageUser({ commit }, { object, successCallback }) {
    commit('SET_IS_LOADING', false);
    try {
      await axios.post(`${domain}/add_user_profile`, object);
      commit('SET_STATUS', true);
      successCallback();
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  async sendEmailToUser({ commit }, { object, successCallback }) {
    // commit('SET_IS_LOADING', false);
    try {
      await axios.post(`${domain}/sendEmailToUser`, object, {
        headers: { 'Content-Type': 'application/json' },
      });
      commit('SET_STATUS', true);
      Swal.fire({
        icon: 'success',
        title: 'Email sent successfully',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2500,
      });
      successCallback();
    } catch (error) {
      // Handle error response
      // Show error message using SweetAlert if needed
      if (error.response.status === 400) {
        Swal.fire({
          icon: 'error',
          title: 'Invalid user data. Please try again.',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
      } else if (error.response.status === 500) {
        Swal.fire({
          icon: 'error',
          title: 'An error occurred while sending the email to the user.',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
      }
    }
    // commit('SET_IS_LOADING', true);
  },
  async sendEmailToClientForDress({ commit }, { object, successCallback }) {
    // commit('SET_IS_LOADING', false);
    try {
      await axios.post(`${domain1}/sendEmailToClient`, object, {
        headers: { 'Content-Type': 'application/json' },
      });
      commit('SET_STATUS', true);
      Swal.fire({
        icon: 'success',
        title: 'Email sent successfully',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2500,
      });
      successCallback();
    } catch (error) {
      // Handle error response
      // Show error message using SweetAlert if needed
      if (error.response.status === 400) {
        Swal.fire({
          icon: 'error',
          title: 'Invalid user data. Please try again.',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
      } else if (error.response.status === 500) {
        Swal.fire({
          icon: 'error',
          title: 'An error occurred while sending the email to the user.',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
      }
    }
    // commit('SET_IS_LOADING', true);
  },
  async edit_feedback({ commit }, { feedbackId, object, successCallback }) {
    commit('SET_IS_LOADING', false);
    try {
      await axios.put(`${domain}/edit_feedback?feedbackId=${feedbackId}`, object);
      commit('SET_STATUS', true);
      Swal.fire({
        icon: 'success',
        title: 'User feedback is edited successfully',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2500,
      });
    } catch (error) {
      // Handle error response
      // Show error message using SweetAlert if needed
      Swal.fire({
        icon: 'error',
        title: 'An error occurred while deactivating the user.',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2500,
      });
    }
    commit('SET_IS_LOADING', true);
    successCallback();
  },
  async editStores({ commit }, { storeId, object, successCallback }) {
    commit('SET_IS_LOADING', false);
    try {
      await axios.put(`${domain3}/editStoreById/storeId?storeId=${storeId}`, object);
      commit('SET_STATUS', true);
      Swal.fire({
        icon: 'success',
        title: 'Store edited successfully',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2500,
      });
    } catch (error) {
      // Handle error response
      // Show error message using SweetAlert if needed
      Swal.fire({
        icon: 'error',
        title: 'An error occurred while editing the store.',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2500,
      });
    }
    commit('SET_IS_LOADING', true);
    successCallback();
  },
  async loadUserPDF({ commit }, { pageNumber, pageSize, fullName }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/getUsersPagination`, {
        params: {
          pageNumber,
          pageSize,
          fullName,
        },
      });
      commit('SET_USER_PDF', response.data);
      commit('SET_CURRENTPAGE', response.data.currentPage);
      commit('SET_TOTALITEMS_USERS', response.data.totalItems);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  async loadEmployeePaymentPagination({ commit }, {
    firstName, lastName, employeeType, pageNumber, pageSize,
  }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/employeePaymentPagination`, {
        params: {
          firstName, lastName, employeeType, pageNumber, pageSize,
        },
      });
      commit('SET_EMPLOYEE_PAYMENT_PAGINATION', response.data.items);
      commit('SET_CURRENTPAGE', response.data.currentPage);
      commit('SET_TOTALITEMS_USERS', response.data.totalItems);
    } catch (error) {
      commit('SET_IS_LOADING', true);
    }
    commit('SET_IS_LOADING', true);
  },
  async loadMonthlyOverviewPaymentPagination({ commit }, {
    year, month, employeeType, filterPaidEmployees, pageNumber, pageSize,
  }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/monthlyOverviewPaymentPagination`, {
        params: {
          year, month, employeeType, filterPaidEmployees, pageNumber, pageSize,
        },
      });
      commit('SET_MONTHLY_OVERVIEW_PAYMENT_PAGINATION', response.data.items);
      commit('SET_CURRENTPAGE_2', response.data.currentPage);
      commit('SET_TOTALITEMS_USERS_2', response.data.totalItems);
    } catch (error) {
      commit('SET_IS_LOADING', true);
    }
    commit('SET_IS_LOADING', true);
  },
  async loadPayments({ commit }, {
    userId, year,
  }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/payment`, {
        params: {
          userId, year,
        },
      });
      commit('SET_PAYMENT', response.data);
    } catch (error) {
      commit('SET_IS_LOADING', true);
    }
    commit('SET_IS_LOADING', true);
  },
  async loadStoresByDropdown({ commit }, {
    amountOfRecords,
  }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain3}/stores_for_Dropdown`, {
        params: {
          amountOfRecords,
        },
      });
      commit('SET_STORES_DROPDOWN', response.data);
    } catch (error) {
      commit('SET_IS_LOADING', true);
    }
    commit('SET_IS_LOADING', true);
  },
  async getInactiveUsersPagination({ commit }, { pageNumber, pageSize, fullName }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/inactiveUsersPagination`, {
        params: {
          pageNumber,
          pageSize,
          fullName,
        },
      });
      commit('SET_INACTIVEUSERS', response.data.items);
      commit('SET_CURRENTPAGE', response.data.currentPage);
      commit('SET_TOTALITEMS_INACTIVEUSERS', response.data.totalItems);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  async fullUserById({ commit }, userId, successCallback) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/fullUserById`,
        {
          params: {
            userId,
          },
        })
      commit('SET_FULL_USERS_BY_ID', response.data)
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
    successCallback();
  },
  async getUsersRole({ commit }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/getUserRole`)
      commit('SET_USER_ROLE', response.data)
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  async addUser({ commit }, { object, successCallback }) {
    commit('SET_IS_LOADING', false);
    try {
      await axios.post(`${domain}/addUser`, object, {
        headers: { 'Content-Type': 'application/json' },
      });
      commit('SET_STATUS', true);
      Swal.fire({
        icon: 'success',
        title: 'User created successfully',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2500,
      });

      // Call the success callback if provided
      if (successCallback && typeof successCallback === 'function') {
        successCallback();
      }
    } catch (error) {
      if (error.response.status === 400) {
        Swal.fire({
          icon: 'error',
          title: 'Please provide a new user',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
      } else if (error.response.status === 500) {
        Swal.fire({
          icon: 'error',
          title: error.response.data.Message,
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
        commit('SET_IS_LOADING', true);
      }
    }
    commit('SET_IS_LOADING', true);
  },
  async deactivateUserById({ commit }, { id, successCallback }) {
    commit('SET_IS_LOADING', false);
    try {
      await axios.put(`${domain}/deactivateUserById?userId=${id}`);
      commit('SET_STATUS', true);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
    successCallback();
  },
  async activateUserById({ commit }, { id, successCallback }) {
    commit('SET_IS_LOADING', false);
    try {
      await axios.put(`${domain}/activateUserById?userId=${id}`);
      commit('SET_STATUS', true);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
    successCallback();
  },
  async editUserById({ commit }, { object, successCallback }) {
    commit('SET_IS_LOADING', false);
    try {
      await axios.put(`${domain}/editUserById?userId=${object.userId}`, object);
      commit('SET_STATUS', true);
      successCallback();
      Swal.fire({
        icon: 'success',
        title: 'User edited succesfully',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2500,
      });
    } catch (error) {
      // Handle error response
      // Show error message using SweetAlert if needed
      if (error.response.status === 400) {
        Swal.fire({
          icon: 'error',
          title: 'Invalid user data. Please try again.',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
      } else if (error.response.status === 500) {
        Swal.fire({
          icon: 'error',
          title: error.response.data.Message,
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
        commit('SET_IS_LOADING', true);
      }
    }
    commit('SET_IS_LOADING', true);
  },
  async filterActiveUsersByName({ commit }, name) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/activeUsersByFullNameLike?fullName=${name}`);
      commit('SET_FILTERED_ACTIVE_USERS', response.data);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  resetFilteredActiveUsers({ commit }) {
    commit('SET_FILTERED_ACTIVE_USERS', []);
  },
  async filterInactiveUsersByName({ commit }, name) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/inactiveUsersByFullNameLike?fullName=${name}`);
      commit('SET_FILTERED_INACTIVEUSERS', response.data);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  resetFilteredInactiveUsers({ commit }) {
    commit('SET_FILTERED_INACTIVEUSERS', []);
  },
  async loadNotifications({ commit }, { pageNumber, pageSize }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/notifications`, {
        params: {
          pageNumber,
          pageSize,

        },
      });
      commit('SET_NOTIFICATIONS', response.data.items);
      commit('SET_CURRENTPAGE', response.data.currentPage);
      commit('SET_TOTALITEMS_USERS', response.data.totalItems);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  async add_payment({ commit }, { object, successCallback }) {
    commit('SET_IS_LOADING', false);
    try {
      await axios.post(`${domain}/add_payment`, object);
      commit('SET_STATUS', true);
      successCallback();
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', false);
  },
  async edit_payment({ commit }, { paymentId, object, successCallback }) {
    commit('SET_IS_LOADING', false);
    try {
      await axios.put(`${domain}/edit_payment?paymentId=${paymentId}`, object);
      commit('SET_STATUS', true);
      successCallback();
    } catch (error) {
      // Handle error response
      // Show error message using SweetAlert if needed

    }
    commit('SET_IS_LOADING', true);
  },
  incrementNotificationCount({ commit }, value) {
    commit('RESET_NOTIFICATION_COUNT_FROM_BACKEND', value);
  },
  resetNotificationCount({ commit }) {
    commit('RESET_NOTIFICATION_COUNT');
  },
  async mark_all_read({ commit }) {
    commit('SET_IS_LOADING', false);
    try {
      await axios.post(`${domain}/mark_all_read`);
      commit('RESET_NOTIFICATION_COUNT_FROM_BACKEND', true);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  async mark_read({ commit }, notificationId) {
    commit('SET_IS_LOADING', false);
    try {
      await axios.post(`${domain}/mark_read?notificationId=${notificationId}`);
      commit('SET_STATUS', true);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  async notifications_number({ commit }) {
    const response = await axios.get(`${domain}/notifications_number`);
    commit('SET_NOTIFICATION_COUNT_FROM_BACKEND', response.data);
  },
  resetNotificationCountFromBack({ commit }) {
    commit('RESET_NOTIFICATION_COUNT_FROM_BACKEND');
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
