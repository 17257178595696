import axios from 'axios'
import Swal from 'sweetalert2';
import { api } from '../../domainConfig';


/* eslint no-shadow: ["error", { "allow": ["state"] }] */

const domain = api.masterDataDomain;
const domain2 = api.stockManagementDomain;

const state = {
  status: false,
  materials: [],
  materialByArticleNum: [],
  invertoryOverview: [],
  filteredMaterials: [],
  totalItems: 0,
  currentPage: 1,
  filtInvByMNameOrMVName: [],
  materialVariantLocation: [],
  variantsDropdownByName: [],
  variantsDropdownByCode: [],
  materialVariantById: [],
  reportingInventoryProductSizesByMDCColorName: [],
  materialSellingReport: [],
  materialSellingCurrentPage: [],
  materialSellingTotalItems: [],
};

const getters = {
  getReportingInventoryProductSizesByMDCColorName: (state) => { return state.reportingInventoryProductSizesByMDCColorName },
  getMaterials: (state) => { return state.materials },
  getStatus: (state) => { return state.status },
  getMaterialsByArticleNum: (state) => { return state.materialByArticleNum },
  getInvertoryOverview: (state) => { return state.invertoryOverview },
  getTotalItemsForMaterials: (state) => {
    return state.totalItems;
  },
  getCurrentPageForMaterials: (state) => {
    return state.currentPage;
  },
  getFiltInvByMorMVName: (state) => {
    return state.filtInvByMNameOrMVName;
  },
  getFilteredMaterials: (state) => {
    return state.filteredMaterials;
  },
  getMaterialVariantlocation: (state) => {
    return state.materialVariantLocation
  },
  getVariantsDropdownByName: (state) => {
    return state.variantsDropdownByName
  },
  getVariantsDropdownByCode: (state) => {
    return state.variantsDropdownByCode
  },
  getMaterialVariantById: (state) => {
    return state.materialVariantById
  },
  getMaterialSellingReport: (state) => {
    return state.materialSellingReport
  },
  getMaterialSellingCurrentPage: (state) => {
    return state.materialSellingCurrentPage
  },
  getMaterialSellingTotalItems: (state) => {
    return state.materialSellingTotalItems
  },
}

const mutations = {
  SET_STATUS(state, payload) {
    state.status = payload;
  },
  SET_MATERIALS(state, latest) {
    state.materials = latest;
  },
  // RESTART(state, latest) {
  //   state.materials = latest;
  // },
  SET_MATERIAL_BY_ARTICLE_NUMBER(state, latest) {
    state.materialByArticleNum = latest;
  },
  SET_INVERTORY_OVERVIEW(state, latest) {
    state.invertoryOverview = latest;
  },
  SET_TOTALITEMS_MATERIALS(state, latest) {
    state.totalItems = latest;
  },
  SET_CURRENTPAGE(state, payload) {
    state.currentPage = payload;
  },
  SET_FILTERED_MATERIALS(state, latest) {
    state.filteredMaterials = latest;
  },
  SET_FILTMORMVNAME(state, latest) {
    state.filtInvByMNameOrMVName = latest;
  },
  SET_MATERIAL_VARIANT_LOCATION(state, latest) {
    state.materialVariantLocation = latest
  },
  SET_VARIANTS_DROPDOWN_BY_NAME(state, latest) {
    state.variantsDropdownByName = latest
  },
  SET_VARIANTS_DROPDOWN_BY_CODE(state, latest) {
    state.variantsDropdownByCode = latest
  },
  SET_MATERIAL_VARIANT_BY_ID(state, latest) {
    state.materialVariantById = latest
  },

  SET_REPORTING_INVERTORY_PRODUCT_SIZES_BY_MD(state, latest) {
    state.reportingInventoryProductSizesByMDCColorName = latest
  },
  SET_MATERIAL_SELLING_REPORT(state, latest) {
    state.materialSellingReport = latest
  },
  SET_MATERIAL_SELLING_CURRENT_PAGE(state, latest) {
    state.materialSellingCurrentPage = latest
  },
  SET_MATERIAL_SELLING_TOTAL_ITEMS(state, latest) {
    state.materialSellingTotalItems = latest
  },
};

const actions = {
  async reporting_inventoryProductSizesByMDCColorName({ commit }, { name, pageNumber, pageSize }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/reporting_inventoryProductSizesByMDCColorName`, {
        params: {
          name, pageNumber, pageSize,
        },
      });
      commit('SET_REPORTING_INVERTORY_PRODUCT_SIZES_BY_MD', response.data.items);
      commit('SET_CURRENTPAGE', response.data.currentPage);
      commit('SET_TOTALITEMS_MATERIALS', response.data.totalItems);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  async loadInventoryOverview({ commit }, { pageNumber, pageSize, materialName }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/inventoryReportingMaterialPagination`, {
        params: {
          pageNumber,
          pageSize,
          materialName,
        },
      });
      commit('SET_INVERTORY_OVERVIEW', response.data.items);
      commit('SET_CURRENTPAGE', response.data.currentPage);
      commit('SET_TOTALITEMS_MATERIALS', response.data.totalItems);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  async materialSellingReport({ commit }, {
    pageNumber, pageSize, startDate, endDate,
  }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain2}/material_sellingReporting_minimalPagination`, {
        params: {
          pageNumber,
          pageSize,
          startDate,
          endDate,
        },
      });
      commit('SET_MATERIAL_SELLING_REPORT', response.data.items);
      commit('SET_MATERIAL_SELLING_CURRENT_PAGE', response.data.currentPage);
      commit('SET_MATERIAL_SELLING_TOTAL_ITEMS', response.data.totalItems);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  async addMaterial({ commit }, { object, successCallback }) {
    commit('SET_IS_LOADING', false);
    // const objToJson = JSON.stringify(object)
    try {
      const response = await axios.post(`${domain}/addMaterial`, object, {
        headers: { 'Content-Type': 'application/json' },
      });
      commit('SET_STATUS', true);
      successCallback(response.data);
      Swal.fire({
        icon: 'success',
        title: 'Material added succesfully',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2500,
      });
    } catch (error) {
      // Handle error response
      // Show error message using SweetAlert if needed
      if (error.response.status === 400) {
        Swal.fire({
          icon: 'error',
          title: 'Invalid material data. Please try again.',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
      } else if (error.response.status === 500) {
        Swal.fire({
          icon: 'error',
          title: error.response.data.Message,
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
        commit('SET_IS_LOADING', true);
      }
    }
    commit('SET_IS_LOADING', true);
  },
  async loadMaterials({ commit }, {
    pageNumber, pageSize, materialName, measurementType,
  }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/materialsPagination`, {
        params: {
          pageNumber,
          pageSize,
          materialName,
          measurementType,
        },
      });
      commit('SET_MATERIALS', response.data.items);
      commit('SET_CURRENTPAGE', response.data.currentPage);
      commit('SET_TOTALITEMS_MATERIALS', response.data.totalItems);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  async editMaterialReq({ commit }, { object, successCallback }) {
    commit('SET_IS_LOADING', false);
    try {
      await axios.put(`${domain}/editMaterial/id?materialId=${object.materialId}`, object);
      commit('SET_STATUS', true);
      successCallback();
      Swal.fire({
        icon: 'success',
        title: 'Material edited succesfully',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2500,
      });
    } catch (error) {
      // Handle error response
      // Show error message using SweetAlert if needed
      if (error.response.status === 400) {
        Swal.fire({
          icon: 'error',
          title: 'Invalid material data. Please try again.',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
      } else if (error.response.status === 500) {
        Swal.fire({
          icon: 'error',
          title: error.response.data.Message,
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
        commit('SET_IS_LOADING', true);
      }
    }
    commit('SET_IS_LOADING', true);
  },
  async productsByArticleNumber({ commit }, id) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(
        `${domain}/inventoryProductMaterialsByArticleNumber_new?articleNumber=${id}`,
      );
      commit('SET_MATERIAL_BY_ARTICLE_NUMBER', response.data);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  resetProductsByArticleNumber({ commit }) {
    commit('SET_MATERIAL_BY_ARTICLE_NUMBER', []);
  },
  async filteredMaterialsByName({ commit }, { materialName, measurementType }) {
    commit('SET_IS_LOADING', false);
    let url = `${domain}/materialNamesByNameLike?materialName=${materialName}`;
    if (measurementType !== null) {
      url += `&measurementType=${measurementType}`;
    }
    try {
      const response = await axios.get(url);
      commit('SET_FILTERED_MATERIALS', response.data);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  resetFilteredMaterials({ commit }) {
    commit('SET_FILTERED_MATERIALS', []);
  },
  async getInvMaterialMNameORMVNameLike({ commit }, { materialName, materialVariant }) {
    if (materialName == '' && materialVariant == '') {
      commit('SET_FILTERED_SUPPLIERS', []);
      return;
    }
    if (materialVariant == '') {
      const response = await axios.get(
        `${domain}/inventoryReportingMaterialNameLike?materialName=${materialName}`,
      );
      commit('SET_FILTMORMVNAME', response.data);
    } else if (materialName == '') {
      const response2 = await axios.get(
        `${domain}/inventoryReportingMaterialVariantNameLike?materialVariantName=${materialVariant}`,
      );
      commit('SET_FILTMORMVNAME', response2.data);
    }
  },
  async add_MaterialVariant_to_Location({ commit }, { object, successCallback }) {
    commit('SET_IS_LOADING', false);
    try {
      await axios.post(
        `${domain}/add_MaterialVariant_to_Location`, object,
      );
      commit('SET_STATUS', true);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
    successCallback()
  },
  async materialVariant_location_Pagination({ commit }, {
    materialVariantId, materialVariantName, materialName, pageNumber, pageSize,
  }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/materialVariant_location_Pagination`, {
        params: {
          materialVariantId, materialVariantName, materialName, pageNumber, pageSize,
        },
      });
      commit('SET_MATERIAL_VARIANT_LOCATION', response.data.items);
      commit('SET_CURRENTPAGE', response.data.currentPage);
      commit('SET_TOTALITEMS_MATERIALS', response.data.totalItems);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  async variantsByDropdownName({ commit }, {
    materialVariantName, pageNumber, pageSize,
  }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/variants_drop_down_by_name_like`, {
        params: {
          materialVariantName, pageNumber, pageSize,
        },
      });
      commit('SET_VARIANTS_DROPDOWN_BY_NAME', response.data.items);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  resetVariantsName({ commit }) {
    commit('SET_VARIANTS_DROPDOWN_BY_NAME', []);
  },
  async variantsByDropdownCode({ commit }, {
    code, pageNumber, pageSize,
  }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/variants_drop_down_by_code_like`, {
        params: {
          code, pageNumber, pageSize,
        },
      });
      commit('SET_VARIANTS_DROPDOWN_BY_CODE', response.data.items);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  resetVariantsCode({ commit }) {
    commit('SET_VARIANTS_DROPDOWN_BY_CODE', []);
  },
  async getMaterialVariantById_new({ commit }, { id }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/getMaterialVariantById_new?id=${id}`);
      commit('SET_MATERIAL_VARIANT_BY_ID', response.data);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },

};

export default {
  state,
  getters,
  mutations,
  actions,
}
