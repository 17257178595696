<template>
  <section
    v-if="getIsSidebarHidden == false"
    class="sidebar"
    style="margin-top: 0rem; box-shadow: none; padding-bottom: 0%; padding-top: 0%;"
    :class="{ hide: getIsSidebarHidden == true,
              show: getIsSidebarHidden == false,
              'hovered': isHovered }"
    @mouseover="setHovered(true)"
    @mouseout="setHovered(false)"
  >
    <div class="allButtons">
      <div>
        <b-list-group>
          <b-list-group-item
            button
            style="border-bottom: 1px solid #DDE0E5 !important; height: 60px;"
          >
            <router-link
              to="/"
              style="background-color: white !important;border: none !important;"
            >
              <img
                src="@/assets/images/nota.png"
                width="35px"
                alt="test"
                class="image1"
              >
              <img
                src="@/assets/images/notaFull.svg"
                width="220px"
                alt="test"
                class="image2"
              >
            </router-link>
          </b-list-group-item>
          <b-list-group-item
            v-if="
              getLoggedInUser.role !== 'Sewer' &&
                getLoggedInUser.role !== 'Trainee' &&
                getLoggedInUser.role !== 'PatternMaker' &&
                getLoggedInUser.role !== 'StockManager' &&
                getLoggedInUser.role !== 'StockManagerMaterialVariant' &&
                getLoggedInUser.role !== 'StockManagerMaterialAndProduct' &&
                getLoggedInUser.role !== 'OnlineSales' &&
                getLoggedInUser.role !== 'Designer' &&
                getLoggedInUser.role !== 'SalesPerson' &&
                getLoggedInUser.role !== 'Receptionist' &&
                getLoggedInUser.role !== 'Accountant' &&
                getLoggedInUser.role !== 'Security' &&
                getLoggedInUser.role !== 'Chauffer' &&
                getLoggedInUser.role !== 'CleaningPerson' &&
                getLoggedInUser.role !== 'HR' &&
                getLoggedInUser.role !== 'StockManagerWarehousProductions' &&
                getLoggedInUser.role !== 'HRFinance' &&
                getLoggedInUser.role !== 'Deliverer' &&
                getLoggedInUser.role !== 'TeamLeaderPattern'
            "
            button
          >
            <router-link
              to="/"
              @click.native="clickLink"
            >
              <img
                src="@/assets/images/dashboard.svg"
                width="30px"
                alt="test"
              >
              <p>{{ $t("Dashboard") }}</p>
            </router-link>
          </b-list-group-item>
          <b-list-group-item
            v-if="
              getLoggedInUser.role !== 'Deliverer' &&
                getLoggedInUser.role !== 'TeamLeader' &&
                getLoggedInUser.role !== 'Sewer' &&
                getLoggedInUser.role !== 'Trainee' &&
                getLoggedInUser.role !== 'PatternMaker' &&
                getLoggedInUser.role !== 'StockManager' &&
                getLoggedInUser.role !== 'StockManagerMaterialVariant' &&
                getLoggedInUser.role !== 'StockManagerMaterialAndProduct' &&
                getLoggedInUser.role !== 'Accountant' &&
                getLoggedInUser.role !== 'Security' &&
                getLoggedInUser.role !== 'Chauffer' &&
                getLoggedInUser.role !== 'CleaningPerson' &&
                getLoggedInUser.role !== 'HR' &&
                getLoggedInUser.role !== 'StockManagerWarehousProductions' &&
                getLoggedInUser.role !== 'HRFinance' &&
                getLoggedInUser.role !== 'Deliverer' &&
                getLoggedInUser.role !== 'TeamLeaderPattern'
            "
            v-b-toggle.collapse-88
            button
            @click="reset"
          >
            <router-link
              to="/create-order"
              :class="{ 'active' : $route.path === '/sell-products'}"
            >
              <img
                src="@/assets/images/createorder.svg"
                width="30px"
                alt="test"
              >
              <p>{{ $t("CreateOrder") }}</p>
            </router-link>
            <div class="collapse-icon">
              <b-icon-chevron-down />
            </div>
          </b-list-group-item>
          <b-collapse
            id="collapse-88"
            class="mt-2"
            accordion="my-accordion"
          >
            <b-list-group-item
              button
              @click="resetTotalStock"
            >
              <router-link
                to="/sell-products"
                @click.native="clickLink"
              >
                <p>{{ $t("SellProductsFromStock") }}</p>
              </router-link>
            </b-list-group-item>
          </b-collapse>
          <b-list-group-item
            v-if="
              getLoggedInUser.role !== 'Sewer' &&
                getLoggedInUser.role !== 'Trainee' &&
                getLoggedInUser.role !== 'PatternMaker' &&
                getLoggedInUser.role !== 'OnlineSales' &&
                getLoggedInUser.role !== 'Designer' &&
                getLoggedInUser.role !== 'SalesPerson' &&
                getLoggedInUser.role !== 'Accountant' &&
                getLoggedInUser.role !== 'Security' &&
                getLoggedInUser.role !== 'Chauffer' &&
                getLoggedInUser.role !== 'CleaningPerson' &&
                getLoggedInUser.role !== 'HR' &&
                getLoggedInUser.role !== 'StockManagerMaterialAndProduct' &&
                getLoggedInUser.role !== 'StockManagerWarehousProductions' &&
                getLoggedInUser.role !== 'HRFinance' &&
                getLoggedInUser.role !== 'Deliverer'
            "
            v-b-toggle.collapse-126
            button
          >
            <router-link
              to="/orders-managementfa"
              class="disabled-link"
              :class="{ 'active' : $route.path === '/create-order-nominative' || $route.path === '/internal-moving-materials' || $route.path === '/orders-nominative' || $route.path === '/materials-management' || $route.path === '/materials-overview' || $route.path === '/selling-report'}"
            >
              <img
                src="@/assets/images/material.svg"
                width="30px"
                alt="test"
              >
              <p>{{ $t("CreateFabricOrder") }}</p>
            </router-link>
            <div class="collapse-icon">
              <b-icon-chevron-down />
            </div>
          </b-list-group-item>
          <b-collapse
            id="collapse-126"
            class="mt-2"
            accordion="my-accordion"
          >
            <b-list-group-item
              v-if="
                getLoggedInUser.role !== 'Deliverer' &&
                  getLoggedInUser.role !== 'TeamLeader' &&
                  getLoggedInUser.role !== 'Sewer' &&
                  getLoggedInUser.role !== 'Trainee' &&
                  getLoggedInUser.role !== 'PatternMaker' &&
                  getLoggedInUser.role !== 'StockManager' &&
                  getLoggedInUser.role !== 'StockManagerMaterialVariant' &&
                  getLoggedInUser.role !== 'StockManagerMaterialAndProduct' &&
                  getLoggedInUser.role !== 'Accountant' &&
                  getLoggedInUser.role !== 'Security' &&
                  getLoggedInUser.role !== 'Chauffer' &&
                  getLoggedInUser.role !== 'CleaningPerson' &&
                  getLoggedInUser.role !== 'HR' &&
                  getLoggedInUser.role !== 'StockManagerWarehousProductions' &&
                  getLoggedInUser.role !== 'HRFinance' &&
                  getLoggedInUser.role !== 'Deliverer' &&
                  getLoggedInUser.role !== 'TeamLeaderPattern'
              "
              button
              @click="reset"
            >
              <router-link
                to="/create-order-nominative"
                @click.native="clickLink"
              >
                <!-- <b-icon-folder /> -->


                <p>{{ $t("SellFabric") }}</p>
              </router-link>
            </b-list-group-item>
            <b-list-group-item
              v-if="
                getLoggedInUser.role !== 'Deliverer' &&
                  getLoggedInUser.role !== 'TeamLeader' &&
                  getLoggedInUser.role !== 'Sewer' &&
                  getLoggedInUser.role !== 'Trainee' &&
                  getLoggedInUser.role !== 'PatternMaker' &&
                  getLoggedInUser.role !== 'StockManager' &&
                  getLoggedInUser.role !== 'StockManagerMaterialVariant' &&
                  getLoggedInUser.role !== 'StockManagerMaterialAndProduct' &&
                  getLoggedInUser.role !== 'Accountant' &&
                  getLoggedInUser.role !== 'Security' &&
                  getLoggedInUser.role !== 'Chauffer' &&
                  getLoggedInUser.role !== 'CleaningPerson' &&
                  getLoggedInUser.role !== 'HR' &&
                  getLoggedInUser.role !== 'StockManagerWarehousProductions' &&
                  getLoggedInUser.role !== 'HRFinance' &&
                  getLoggedInUser.role !== 'Deliverer' &&
                  getLoggedInUser.role !== 'TeamLeaderPattern'
              "
              button
              @click="reset"
            >
              <router-link
                to="/orders-nominative"
                @click.native="clickLink"
              >
                <!-- <b-icon-folder /> -->


                <p>{{ $t("FabricOrders") }}</p>
              </router-link>
            </b-list-group-item>
            <b-list-group-item
              v-if="
                getLoggedInUser.role !== 'Deliverer' &&
                  getLoggedInUser.role !== 'TeamLeader' &&
                  getLoggedInUser.role !== 'Sewer' &&
                  getLoggedInUser.role !== 'Trainee' &&
                  getLoggedInUser.role !== 'PatternMaker' &&
                  getLoggedInUser.role !== 'StockManager' &&
                  getLoggedInUser.role !== 'StockManagerMaterialVariant' &&
                  getLoggedInUser.role !== 'StockManagerMaterialAndProduct' &&
                  getLoggedInUser.role !== 'Accountant' &&
                  getLoggedInUser.role !== 'Security' &&
                  getLoggedInUser.role !== 'Chauffer' &&
                  getLoggedInUser.role !== 'CleaningPerson' &&
                  getLoggedInUser.role !== 'HR' &&
                  getLoggedInUser.role !== 'StockManagerWarehousProductions' &&
                  getLoggedInUser.role !== 'HRFinance' &&
                  getLoggedInUser.role !== 'Deliverer' &&
                  getLoggedInUser.role !== 'TeamLeaderPattern'
              "
              button
              @click="reset"
            >
              <router-link
                to="/materials-overview"
                @click.native="clickLink"
              >
                <!-- <b-icon-folder /> -->


                <p>{{ $t("MaterialsOverview") }}</p>
              </router-link>
            </b-list-group-item>
            <b-list-group-item
              v-if="
                getLoggedInUser.role !== 'Deliverer' &&
                  getLoggedInUser.role !== 'TeamLeader' &&
                  getLoggedInUser.role !== 'Sewer' &&
                  getLoggedInUser.role !== 'Trainee' &&
                  getLoggedInUser.role !== 'PatternMaker' &&
                  getLoggedInUser.role !== 'StockManager' &&
                  getLoggedInUser.role !== 'StockManagerMaterialVariant' &&
                  getLoggedInUser.role !== 'StockManagerMaterialAndProduct' &&
                  getLoggedInUser.role !== 'Accountant' &&
                  getLoggedInUser.role !== 'Security' &&
                  getLoggedInUser.role !== 'Chauffer' &&
                  getLoggedInUser.role !== 'CleaningPerson' &&
                  getLoggedInUser.role !== 'HR' &&
                  getLoggedInUser.role !== 'StockManagerWarehousProductions' &&
                  getLoggedInUser.role !== 'HRFinance' &&
                  getLoggedInUser.role !== 'Deliverer' &&
                  getLoggedInUser.role !== 'TeamLeaderPattern'
              "
              button
            >
              <router-link
                to="/materials-management"
                @click.native="clickLink"
              >
                <!-- <b-icon-folder /> -->


                <p>{{ $t("MaterialsManagement") }}</p>
              </router-link>
            </b-list-group-item>
            <b-list-group-item
              v-if="
                getLoggedInUser.role !== 'Deliverer' &&
                  getLoggedInUser.role !== 'TeamLeader' &&
                  getLoggedInUser.role !== 'Sewer' &&
                  getLoggedInUser.role !== 'Trainee' &&
                  getLoggedInUser.role !== 'PatternMaker' &&
                  getLoggedInUser.role !== 'StockManager' &&
                  getLoggedInUser.role !== 'StockManagerMaterialVariant' &&
                  getLoggedInUser.role !== 'StockManagerMaterialAndProduct' &&
                  getLoggedInUser.role !== 'Accountant' &&
                  getLoggedInUser.role !== 'Security' &&
                  getLoggedInUser.role !== 'Chauffer' &&
                  getLoggedInUser.role !== 'CleaningPerson' &&
                  getLoggedInUser.role !== 'HR' &&
                  getLoggedInUser.role !== 'StockManagerWarehousProductions' &&
                  getLoggedInUser.role !== 'HRFinance' &&
                  getLoggedInUser.role !== 'Deliverer' &&
                  getLoggedInUser.role !== 'TeamLeaderPattern'
              "
              button
            >
              <router-link
                to="/selling-report"
                @click.native="clickLink"
              >
                <!-- <b-icon-folder /> -->


                <p>{{ $t("SellingReport") }}</p>
              </router-link>
            </b-list-group-item>
            <b-list-group-item
              button
              @click="resetInternal"
            >
              <router-link
                to="/internal-moving-materials"
                @click.native="clickLink"
              >
                <p class="card-text">
                  {{ $t("InternalMoving") }}
                </p>
              </router-link>
            </b-list-group-item>
          </b-collapse>
          <b-list-group-item
            v-if="
              getLoggedInUser.role !== 'Sewer' &&
                getLoggedInUser.role !== 'Trainee' &&
                getLoggedInUser.role !== 'PatternMaker' &&
                getLoggedInUser.role !== 'OnlineSales' &&
                getLoggedInUser.role !== 'Designer' &&
                getLoggedInUser.role !== 'SalesPerson' &&
                getLoggedInUser.role !== 'Accountant' &&
                getLoggedInUser.role !== 'Security' &&
                getLoggedInUser.role !== 'Chauffer' &&
                getLoggedInUser.role !== 'CleaningPerson' &&
                getLoggedInUser.role !== 'HR' &&
                getLoggedInUser.role !== 'StockManagerMaterialAndProduct' &&
                getLoggedInUser.role !== 'StockManagerWarehousProductions' &&
                getLoggedInUser.role !== 'HRFinance' &&
                getLoggedInUser.role !== 'Deliverer'
            "
            v-b-toggle.collapse-12
            button
          >
            <router-link
              to="/orders-managementff"
              class="disabled-link"
              :class="{ 'active' : $route.path === '/orders-management'|| $route.path === '/new-orders-edit' || $route.path === '/production-management' || $route.path === '/order-item-tracking' || $route.path === '/admin' || $route.path === '/deliverer-orders-management/ReadyToDeliver' || $route.path === '/orders-overview/ReadyToDeliver' || $route.path === '/orders-overview/Assigned' || $route.path === '/orders-overview/all' || $route.path === '/order-item-tracking' || $route.path === '/selling' || $route.path === '/internal-moving' || $route.path === '/return-process'}"
            >
              <img
                src="@/assets/images/ordermanagement.svg"
                width="30px"
                alt="test"
              >
              <p>{{ $t("OrdersManagement") }}</p>
            </router-link>
            <div class="collapse-icon">
              <b-icon-chevron-down />
            </div>
          </b-list-group-item>

          <b-collapse
            id="collapse-12"
            class="mt-2"
            accordion="my-accordion"
          >
            <b-list-group-item
              v-if="
                getLoggedInUser.role !== 'Deliverer' &&
                  getLoggedInUser.role !== 'TeamLeader' &&
                  getLoggedInUser.role !== 'Trainee' &&
                  getLoggedInUser.role !== 'StockManagerMaterialVariant' &&
                  getLoggedInUser.role !== 'StockManagerMaterialAndProduct' &&
                  getLoggedInUser.role !== 'PatternMaker' &&
                  getLoggedInUser.role !== 'StockManager'
              "
              button
              @click="reset"
            >
              <router-link
                to="/orders-management"
                @click.native="clickLink"
              >
                <p class="card-text">
                  {{ $t("NewOrders") }}
                </p>
              </router-link>
            </b-list-group-item>
            <b-list-group-item
              v-if="
                getLoggedInUser.role !== 'Deliverer' &&
                  getLoggedInUser.role !== 'TeamLeader' &&
                  getLoggedInUser.role !== 'Trainee' &&
                  getLoggedInUser.role !== 'StockManagerMaterialVariant' &&
                  getLoggedInUser.role !== 'StockManagerMaterialAndProduct' &&
                  getLoggedInUser.role !== 'PatternMaker' &&
                  getLoggedInUser.role !== 'StockManager'
              "
              button
              @click="resetCreatedOrdersByOrderNumber"
            >
              <router-link
                to="/montage-overview"
                @click.native="clickLink"
              >
                <p class="card-text">
                  {{ $t("MontageOverview") }}
                </p>
              </router-link>
            </b-list-group-item>
            <b-list-group-item
              v-if="
                getLoggedInUser.role == 'CEO' ||
                  getLoggedInUser.role == 'Admin' ||
                  getLoggedInUser.role == 'Manager' ||
                  getLoggedInUser.role == 'TeamLeader' ||
                  getLoggedInUser.role == 'TeamLeaderPattern'
              "
              button
              @click="reset"
            >
              <router-link
                to="/new-orders-edit"
                @click.native="clickLink"
              >
                <!-- <b-icon-folder /> -->


                <p>{{ $t("EditNewOrders") }}</p>
              </router-link>
            </b-list-group-item>
            <b-list-group-item
              v-if="
                getLoggedInUser.role !== 'Deliverer' &&
                  getLoggedInUser.role !== 'Trainee' &&
                  getLoggedInUser.role !== 'StockManagerMaterialVariant' &&
                  getLoggedInUser.role !== 'StockManagerMaterialAndProduct' &&
                  getLoggedInUser.role !== 'PatternMaker' &&
                  getLoggedInUser.role !== 'StockManager' &&
                  getLoggedInUser.role !== 'Receptionist'
              "
              button
            >
              <router-link
                to="/production-management"
                @click.native="clickLink"
              >
                <p class="card-text">
                  {{ $t("ProductionManagement") }}
                </p>
              </router-link>
            </b-list-group-item>
            <b-list-group-item
              v-if="
                getLoggedInUser.role == 'Deliverer' &&
                  getLoggedInUser.role !== 'Trainee' &&
                  getLoggedInUser.role !== 'StockManagerMaterialVariant' &&
                  getLoggedInUser.role !== 'StockManagerMaterialAndProduct' &&
                  getLoggedInUser.role !== 'PatternMaker'
              "
              button
            >
              <router-link
                to="/deliverer-orders-management/ReadyToDeliver"
                @click.native="clickLink"
              >
                {{ $t('DelivererOrdersManagement') }}
              </router-link>
            </b-list-group-item>
            <b-list-group-item
              v-if="
                getLoggedInUser.role !== 'Deliverer' &&
                  getLoggedInUser.role !== 'Trainee' &&
                  getLoggedInUser.role !== 'PatternMaker'
              "
              button
            >
              <router-link
                :to="
                  getLoggedInUser.role == 'Deliverer'
                    ? '/orders-overview/ReadyToDeliver'
                    : getLoggedInUser.role == 'TeamLeader'
                      ? '/orders-overview/Assigned'
                      : '/orders-overview/all'
                "
                @click.native="clickLink"
              >
                <p class="card-text">
                  {{ $t("OrdersOverview") }}
                </p>
              </router-link>
            </b-list-group-item>
            <b-list-group-item
              v-if="
                getLoggedInUser.role !== 'Deliverer' &&
                  getLoggedInUser.role !== 'TeamLeader' &&
                  getLoggedInUser.role !== 'Sewer' &&
                  getLoggedInUser.role !== 'Trainee' &&
                  getLoggedInUser.role !== 'PatternMaker' &&
                  getLoggedInUser.role !== 'StockManager' &&
                  getLoggedInUser.role !== 'SalesPerson' &&
                  getLoggedInUser.role !== 'Receptionist' &&
                  getLoggedInUser.role !== 'TeamLeaderPattern'
              "
              button
            >
              <router-link
                to="/order-item-tracking"
                @click.native="clickLink"
              >
                <!-- <img
                src="@/assets/images/9. Order Item Tracking (1).svg"
                width="40px"
                alt="test"
              > -->
                <p>{{ $t("OrderItemTracking") }}</p>
              </router-link>
            </b-list-group-item>
            <b-list-group-item
              v-if="
                getLoggedInUser.role == 'CEO' ||
                  getLoggedInUser.role == 'Admin' ||
                  getLoggedInUser.role == 'Manager'
              "
              button
            >
              <router-link
                to="/admin"
                @click.native="clickLink"
              >
                <p class="card-text">
                  {{ $t("AdminAuthorise") }}
                </p>
              </router-link>
            </b-list-group-item>
            <b-list-group-item
              v-if="
                getLoggedInUser.role == 'CEO' ||
                  getLoggedInUser.role == 'Admin'
              "
              button
            >
              <router-link
                to="/selling"
                @click.native="clickLink"
              >
                <p class="card-text">
                  {{ $t("SellingReport") }}
                </p>
              </router-link>
            </b-list-group-item>
            <b-list-group-item
              button
              @click="resetInternal"
            >
              <router-link
                to="/internal-moving"
                @click.native="clickLink"
              >
                <p class="card-text">
                  {{ $t("InternalMoving") }}
                </p>
              </router-link>
            </b-list-group-item>
            <b-list-group-item
              button
              @click="resetInternal"
            >
              <router-link
                to="/return-process"
                @click.native="clickLink"
              >
                <p class="card-text">
                  {{ $t("ReturnToStock") }}
                </p>
              </router-link>
            </b-list-group-item>
          </b-collapse>

          <b-list-group-item
            v-if="
              getLoggedInUser.role !== 'Deliverer' &&
                getLoggedInUser.role !== 'TeamLeader' &&
                getLoggedInUser.role !== 'Trainee' &&
                getLoggedInUser.role !== 'StockManagerMaterialVariant' &&

                getLoggedInUser.role !== 'OnlineSales' &&
                getLoggedInUser.role !== 'Designer' &&
                getLoggedInUser.role !== 'SalesPerson' &&
                getLoggedInUser.role !== 'PatternMaker' &&
                getLoggedInUser.role !== 'StockManager' &&
                getLoggedInUser.role !== 'Accountant' &&
                getLoggedInUser.role !== 'Security' &&
                getLoggedInUser.role !== 'Chauffer' &&
                getLoggedInUser.role !== 'CleaningPerson' &&
                getLoggedInUser.role !== 'HR' &&
                getLoggedInUser.role !== 'StockManagerWarehousProductions' &&
                getLoggedInUser.role !== 'HRFinance' &&
                getLoggedInUser.role !== 'Deliverer' &&
                getLoggedInUser.role !== 'TeamLeaderPattern'
            "
            v-b-toggle.collapse-3
            button
          >
            <router-link
              to="/nowhere"
              class="disabled-link"
              :class="{ 'active' : $route.path === '/products' || $route.path === '/product-reporting' || $route.path === '/product-configuration'}"
            >
              <img
                src="@/assets/images/products.svg"
                width="30px"
                alt="test"
              >
              <p>{{ $t("Products1") }}</p>
            </router-link>
            <div class="collapse-icon">
              <b-icon-chevron-down />
            </div>
          </b-list-group-item>
          <b-collapse
            id="collapse-3"
            class="mt-2"
            accordion="my-accordion"
          >
            <b-list-group-item
              v-if="
                getLoggedInUser.role !== 'TeamLeader' &&
                  getLoggedInUser.role !== 'Sewer' &&
                  getLoggedInUser.role !== 'Trainee' &&
                  getLoggedInUser.role !== 'PatternMaker' &&
                  getLoggedInUser.role !== 'StockManagerMaterialVariant' &&

                  getLoggedInUser.role !== 'SalesPerson' &&
                  getLoggedInUser.role !== 'Receptionist'
              "
              button
              @click="reset"
            >
              <router-link
                to="/products"
                @click.native="clickLink"
              >
                <p class="card-text">
                  {{ $t("ProductManagement") }}
                </p>
              </router-link>
            </b-list-group-item>
            <b-list-group-item button>
              <router-link
                to="/product-reporting"
                @click.native="clickLink"
              >
                <p class="card-text">
                  {{ $t("ProductReporting") }}
                </p>
              </router-link>
            </b-list-group-item>
            <b-list-group-item button>
              <router-link
                to="/product-configuration"
                @click.native="clickLink"
              >
                <p class="card-text">
                  {{ $t("Configurations") }}
                </p>
              </router-link>
            </b-list-group-item>
          </b-collapse>
          <b-list-group-item
            v-if="getLoggedInUser.role !== 'StockManagerMaterialVariant' && getLoggedInUser.role !== 'Sewer'
              && getLoggedInUser.role !== 'PatternMaker' &&
              getLoggedInUser.role !== 'OnlineSales' &&
              getLoggedInUser.role !== 'Designer' &&
              getLoggedInUser.role !== 'SalesPerson' &&
              getLoggedInUser.role !== 'Accountant'&&
              getLoggedInUser.role !== 'StockManager' &&
              client != 'DrenushaXharra' &&
              getLoggedInUser.role !== 'Security' &&
              getLoggedInUser.role !== 'Chauffer' &&
              getLoggedInUser.role !== 'CleaningPerson' &&
              getLoggedInUser.role !== 'HR' &&
              getLoggedInUser.role !== 'StockManagerWarehousProductions' &&
              getLoggedInUser.role !== 'HRFinance' &&
              getLoggedInUser.role !== 'Deliverer' &&
              getLoggedInUser.role !== 'TeamLeaderPattern'"
            v-b-toggle.collapse-7
            button
          >
            <router-link
              to="/nowhere"
              class="disabled-link"
              :class="{ 'active' : $route.path === '/status-in-stock' || $route.path === '/internal-moving-stock' || $route.path === '/products-stock' || $route.path === '/product-in-stock' || $route.path === '/products-in-stock' || $route.path === '/product-management-in-stock' || $route.path === '/product-overview-in-stock' || $route.path === '/product-in-stock-track-trace'}"
            >
              <!-- <b-icon icon="view-stacked" /> -->
              <img
                src="@/assets/images/stock.svg"
                width="30px"
                alt="test"
              >
              <p>{{ $t("StockinStore") }}</p>
            </router-link>
            <div class="collapse-icon">
              <b-icon-chevron-down />
            </div>
          </b-list-group-item>
          <b-collapse
            id="collapse-7"
            class="mt-2"
            accordion="my-accordion"
          >
            <b-list-group-item
              v-if="
                getLoggedInUser.role !== 'SalesPerson' &&
                  getLoggedInUser.role !== 'TeamLeader' &&
                  getLoggedInUser.role !== 'StockManagerMaterialVariant'
              "
              button
            >
              <router-link
                to="/status-in-stock"
                @click.native="clickLink"
              >
                <p class="card-text">
                  {{ $t("Merchproductionstatus") }}
                </p>
              </router-link>
            </b-list-group-item>
            <b-list-group-item
              v-if="
                getLoggedInUser.role !== 'Deliverer' &&
                  getLoggedInUser.role !== 'TeamLeader' &&
                  getLoggedInUser.role !== 'Sewer' &&
                  getLoggedInUser.role !== 'Trainee' &&
                  getLoggedInUser.role !== 'PatternMaker' &&
                  getLoggedInUser.role !== 'StockManager' &&
                  getLoggedInUser.role !== 'SalesPerson' &&
                  getLoggedInUser.role !== 'StockManagerMaterialVariant'
              "
              button
            >
              <router-link
                to="/product-in-stock"
                @click.native="clickLink"
              >
                <p class="card-text">
                  {{ $t("Productionmerch") }}
                </p>
              </router-link>
            </b-list-group-item>
            <b-list-group-item
              v-if="
                getLoggedInUser.role !== 'SalesPerson' &&
                  getLoggedInUser.role !== 'TeamLeader' &&
                  getLoggedInUser.role !== 'StockManagerMaterialVariant'
              "
              button
            >
              <router-link
                to="/products-in-stock"
                @click.native="clickLink"
              >
                <p class="card-text">
                  {{ $t("MerchInStore") }}
                </p>
              </router-link>
            </b-list-group-item>
            <b-list-group-item
              v-if="
                getLoggedInUser.role !== 'SalesPerson' &&
                  getLoggedInUser.role !== 'TeamLeader' &&
                  getLoggedInUser.role !== 'StockManagerMaterialVariant'
              "
              button
              @click="resetTotalStock"
            >
              <router-link
                to="/products-stock"
                @click.native="clickLink"
              >
                <p class="card-text">
                  {{ $t("ProductsInStock") }}
                </p>
              </router-link>
            </b-list-group-item>
            <b-list-group-item
              v-if="
                getLoggedInUser.role !== 'SalesPerson' &&
                  getLoggedInUser.role !== 'Deliverer' &&
                  getLoggedInUser.role !== 'Trainee' &&
                  getLoggedInUser.role !== 'StockManagerMaterialVariant' &&
                  getLoggedInUser.role !== 'PatternMaker'
              "
              button
            >
              <router-link
                to="/product-management-in-stock"
                @click.native="clickLink"
              >
                <p class="card-text">
                  {{ $t("Merchproductionoverview") }}
                </p>
              </router-link>
            </b-list-group-item>
            <b-list-group-item
              v-if="false"
              button
            >
              <router-link
                to="/product-overview-in-stock"
                @click.native="clickLink"
              >
                <p class="card-text">
                  {{ $t("ProductOverviewInStock") }}
                </p>
              </router-link>
            </b-list-group-item>
            <b-list-group-item
              v-if="
                getLoggedInUser.role !== 'SalesPerson' &&
                  getLoggedInUser.role !== 'TeamLeader' &&
                  getLoggedInUser.role !== 'StockManagerMaterialVariant'
              "
              button
            >
              <router-link
                to="/product-in-stock-track-trace"
                @click.native="clickLink"
              >
                <p class="card-text">
                  {{ $t("Merchproductiontracking") }}
                </p>
              </router-link>
            </b-list-group-item>
            <b-list-group-item
              v-if="
                getLoggedInUser.role !== 'SalesPerson' &&
                  getLoggedInUser.role !== 'TeamLeader' &&
                  getLoggedInUser.role !== 'StockManagerMaterialVariant'
              "
              button
            >
              <router-link
                to="/internal-moving-stock"
                @click.native="clickLink"
              >
                <p class="card-text">
                  {{ $t("InternalMoving") }}
                </p>
              </router-link>
            </b-list-group-item>
          </b-collapse>
          <b-list-group-item
            v-if="getLoggedInUser.role !== 'Deliverer' && getLoggedInUser.role !== 'Receptionist' && getLoggedInUser.role !== 'StockManager' &&
              getLoggedInUser.role !== 'Accountant' &&
              getLoggedInUser.role !== 'Security' &&
              getLoggedInUser.role !== 'Chauffer' &&
              getLoggedInUser.role !== 'CleaningPerson' &&
              getLoggedInUser.role !== 'HR' &&
              getLoggedInUser.role !== 'StockManagerWarehousProductions' &&
              getLoggedInUser.role !== 'HRFinance' &&
              getLoggedInUser.role !== 'Deliverer' &&
              getLoggedInUser.role !== 'TeamLeaderPattern'"
            v-b-toggle.collapse-2
            button
          >
            <router-link
              to="/nowhere"
              class="disabled-link"
              :class="{ 'active' : $route.path === '/inventory' || $route.path === '/inventory-reporting' || $route.path === '/inventory-overview' || $route.path === '/material-colors' || $route.path === '/cart'}"
            >
              <img
                src="@/assets/images/material.svg"
                width="30px"
                alt="test"
              >
              <p>{{ $t('Materials') }}</p>
            </router-link>
            <div class="collapse-icon">
              <b-icon-chevron-down />
            </div>
          </b-list-group-item>
          <b-collapse
            id="collapse-2"
            class="mt-2"
            role="tabpanel"
            accordion="my-accordion"
          >
            <b-list-group-item
              v-if="
                getLoggedInUser.role !== 'TeamLeader' &&
                  getLoggedInUser.role !== 'Sewer' &&
                  getLoggedInUser.role !== 'Trainee' &&
                  getLoggedInUser.role !== 'PatternMaker' &&
                  getLoggedInUser.role !== 'OnlineSales' &&
                  getLoggedInUser.role !== 'Designer' &&
                  getLoggedInUser.role !== 'SalesPerson' &&
                  getLoggedInUser.role !== 'Receptionist'
              "
              button
            >
              <router-link
                to="/inventory"
                @click.native="clickLink"
              >
                <p class="card-text">
                  {{ $t("InventoryManagement") }}
                </p>
              </router-link>
            </b-list-group-item>
            <b-list-group-item
              v-if="getLoggedInUser.role !== 'OnlineSales' &&
                getLoggedInUser.role !== 'Designer' &&
                getLoggedInUser.role !== 'SalesPerson'"
              button
            >
              <router-link
                to="/inventory-reporting"
                @click.native="clickLink"
              >
                <p class="card-text">
                  {{ $t("InventoryReporting") }}
                </p>
              </router-link>
            </b-list-group-item>
            <b-list-group-item
              v-if="
                getLoggedInUser.role !== 'Receptionist'"
              button
            >
              <router-link
                to="/inventory-overview"
                @click.native="clickLink"
              >
                <p class="card-text">
                  {{ $t("InventoryOverview") }}
                </p>
              </router-link>
            </b-list-group-item>
            <!-- <b-list-group-item
            v-if="getLoggedInUser.role !== 'OnlineSales' &&
              getLoggedInUser.role !== 'Designer' &&
              getLoggedInUser.role !== 'SalesPerson' && getLoggedInUser.role !== 'Receptionist' && client != 'DrenushaXharra'"
            button
          >
            <router-link
              to="/material-variant-location"
              @click.native="clickLink('CreateOrder')"
            >
              <p class="card-text">
                {{ $t("MaterialVariantLocation") }}
              </p>
            </router-link>
          </b-list-group-item> -->
            <b-list-group-item
              v-if="getLoggedInUser.role !== 'OnlineSales' &&
                getLoggedInUser.role !== 'Designer' &&
                getLoggedInUser.role !== 'SalesPerson' && getLoggedInUser.role !== 'Receptionist'"
              button
            >
              <router-link
                to="/material-colors"
                @click.native="clickLink"
              >
                <p class="card-text">
                  {{ $t("MaterialColors") }}
                </p>
              </router-link>
            </b-list-group-item>
            <!-- v-if="getLoggedInUser.role !== 'OnlineSales' &&
              getLoggedInUser.role !== 'Designer' &&
              getLoggedInUser.role !== 'SalesPerson' && getLoggedInUser.role !== 'Receptionist'" -->
            <b-list-group-item
              v-if="
                getLoggedInUser.role !== 'TeamLeader' &&
                  getLoggedInUser.role !== 'Sewer' &&
                  getLoggedInUser.role !== 'Trainee' &&
                  getLoggedInUser.role !== 'PatternMaker' &&
                  getLoggedInUser.role !== 'OnlineSales' &&
                  getLoggedInUser.role !== 'Designer' &&
                  getLoggedInUser.role !== 'SalesPerson' &&
                  getLoggedInUser.role !== 'Receptionist'
              "
              button
            >
              <router-link
                to="/cart"
                @click.native="clickLink"
              >
                <p class="card-text">
                  {{ $t("Cart") }}
                </p>
              </router-link>
            </b-list-group-item>
            <b-list-group-item
              v-if="
                getLoggedInUser.role !== 'TeamLeader' &&
                  getLoggedInUser.role !== 'Sewer' &&
                  getLoggedInUser.role !== 'Trainee' &&
                  getLoggedInUser.role !== 'PatternMaker' &&
                  getLoggedInUser.role !== 'OnlineSales' &&
                  getLoggedInUser.role !== 'Designer' &&
                  getLoggedInUser.role !== 'SalesPerson' &&
                  getLoggedInUser.role !== 'Receptionist'
              "
              button
            >
              <router-link
                to="/buy-direct"
                @click.native="clickLink"
              >
                <p class="card-text">
                  {{ $t("BuyDirect") }}
                </p>
              </router-link>
            </b-list-group-item>
          </b-collapse>

          <b-list-group-item
            v-if="
              getLoggedInUser.role !== 'Deliverer' &&
                getLoggedInUser.role !== 'Sewer' &&
                getLoggedInUser.role !== 'Trainee' &&
                getLoggedInUser.role !== 'PatternMaker' &&
                getLoggedInUser.role !== 'StockManager' &&
                getLoggedInUser.role !== 'StockManagerMaterialAndProduct' &&
                getLoggedInUser.role !== 'StockManagerMaterialVariant' &&
                getLoggedInUser.role !== 'OnlineSales' &&
                getLoggedInUser.role !== 'Designer' &&
                getLoggedInUser.role !== 'SalesPerson' &&
                getLoggedInUser.role !== 'Receptionist' &&
                getLoggedInUser.role !== 'Security' &&
                getLoggedInUser.role !== 'Chauffer' &&
                getLoggedInUser.role !== 'StockManagerWarehousProductions' &&
                getLoggedInUser.role !== 'CleaningPerson' &&
                getLoggedInUser.role !== 'Deliverer' &&
                getLoggedInUser.role !== 'TeamLeaderPattern'
            "
            v-b-toggle.collapse-4
            button
          >
            <router-link
              to="/hr-dashboard"
              :class="{ 'active' : $route.path === '/teams' || $route.path === '/users' || $route.path === '/leave-request' || $route.path === '/calendar' || $route.path === '/employee-feedback' || $route.path === '/bankholidays'}"
            >
              <img
                src="@/assets/images/teams.svg"
                width="30px"
                alt="test"
              >
              <p>{{ $t("HumanResources") }}</p>
            </router-link>
            <div class="collapse-icon">
              <b-icon-chevron-down />
            </div>
          </b-list-group-item>
          <b-collapse
            id="collapse-4"
            class="mt-2"
            accordion="my-accordion"
          >
            <b-list-group-item
              v-if="
                getLoggedInUser.role !== 'Accountant'"
              button
            >
              <router-link
                to="/teams"
                @click.native="clickLink"
              >
                <p class="card-text">
                  {{ $t("TeamsManagement") }}
                </p>
              </router-link>
            </b-list-group-item>
            <b-list-group-item
              v-if="getLoggedInUser.role !== 'TeamLeader' &&
                getLoggedInUser.role !== 'StockManagerMaterialAndProduct' &&
                getLoggedInUser.role !== 'StockManagerMaterialVariant'"
              button
            >
              <router-link
                to="/users"
                @click.native="clickLink"
              >
                <p class="card-text">
                  {{ $t("EmployeeManagement") }}
                </p>
              </router-link>
            </b-list-group-item>
            <!-- <b-list-group-item
              v-if="getLoggedInUser.role !== 'TeamLeader' &&
                getLoggedInUser.role !== 'StockManagerMaterialAndProduct' &&
                getLoggedInUser.role !== 'StockManagerMaterialVariant' &&
                getLoggedInUser.role !== 'Accountant'"
              button
            >
              <router-link
                to="/leave-request"
                @click.native="clickLink"
              >
                <p class="card-text">
                  {{ $t("VacationManagement") }}
                </p>
              </router-link>
            </b-list-group-item>
            <b-list-group-item
              v-if="
                getLoggedInUser.role == 'Manager' ||
                  getLoggedInUser.role == 'CEO' ||
                  getLoggedInUser.role == 'HR' ||
                  getLoggedInUser.role == 'HRFinance' ||
                  getLoggedInUser.role == 'Admin'"
              button
            >
              <router-link
                to="/calendar"
                @click.native="clickLink"
              >
                <p>{{ $t("VacationPlan") }}</p>
              </router-link>
            </b-list-group-item>
            <b-list-group-item
              v-if="
                getLoggedInUser.role == 'Manager' ||
                  getLoggedInUser.role == 'CEO' ||
                  getLoggedInUser.role == 'HR' ||
                  getLoggedInUser.role == 'HRFinance' ||
                  getLoggedInUser.role == 'Admin'
              "
              button
            >
              <router-link
                to="/employee-feedback"
                @click.native="clickLink"
              >
                <p>{{ $t("Feedback") }}</p>
              </router-link>
            </b-list-group-item>
            <b-list-group-item
              v-if="getLoggedInUser.role !== 'TeamLeader' &&
                getLoggedInUser.role !== 'StockManagerMaterialAndProduct' &&
                getLoggedInUser.role !== 'StockManagerMaterialVariant' &&
                getLoggedInUser.role !== 'Accountant'"
              button
            >
              <router-link
                to="/bankholidays"
                @click.native="clickLink"
              >
                <p class="card-text">
                  {{ $t("BankHolidays") }}
                </p>
              </router-link>
            </b-list-group-item> -->
          </b-collapse>
          <b-list-group-item
            v-if="getLoggedInUser.role == 'CEO' || getLoggedInUser.role == 'Admin' || getLoggedInUser.role == 'HRFinance'"
            v-b-toggle.collapse-8
            button
          >
            <router-link
              to="/finance-dashboard"
              :class="{ 'active' : $route.path === '/employee-payments' || $route.path === '/finance-account' || $route.path === '/finance-report' }"
            >

              <img
                src="@/assets/images/finance.svg"
                alt="test"
              >
              <p>{{ $t("Finance") }}</p>
            </router-link>
            <div class="collapse-icon">
              <b-icon-chevron-down />
            </div>
          </b-list-group-item>
          <b-collapse
            id="collapse-8"
            class="mt-2"
            accordion="my-accordion"
          >

            <b-list-group-item
              button
            >
              <router-link
                to="/finance-report"
                @click.native="clickLink"
              >

                <p>{{ $t("FinancialReport") }}</p>
              </router-link>
            </b-list-group-item>

            <b-list-group-item
              button
            >
              <router-link
                to="/employee-payments"
                @click.native="clickLink"
              >

                <p>{{ $t("Salary") }}</p>
              </router-link>
            </b-list-group-item>
            <b-list-group-item
              button
            >
              <router-link
                to="/finance-account"
                @click.native="clickLink"
              >

                <p>{{ $t("Configurations") }}</p>
              </router-link>
            </b-list-group-item>
          </b-collapse>
          <b-list-group-item
            v-if="
              getLoggedInUser.role !== 'OnlineSales' &&
                getLoggedInUser.role !== 'Designer' &&
                getLoggedInUser.role !== 'SalesPerson' && client != 'DrenushaXharra' &&
                getLoggedInUser.role !== 'Accountant' &&
                getLoggedInUser.role !== 'Security' &&
                getLoggedInUser.role !== 'Chauffer' &&
                getLoggedInUser.role !== 'CleaningPerson' &&
                getLoggedInUser.role !== 'HR' &&
                getLoggedInUser.role !== 'HRFinance' &&
                getLoggedInUser.role !== 'Deliverer' &&
                getLoggedInUser.role !== 'TeamLeaderPattern'"
            v-b-toggle.collapse-13
            button
          >
            <router-link
              to="/locatione"
              class="disabled-link"
              :class="{ 'active' : $route.path === '/location' || $route.path === '/order-item-location' || $route.path === '/product-in-stock-location' || $route.path === '/material-variant-location'}"
            >

              <img
                src="@/assets/images/warehouses.svg"
                width="30px"
                alt="test"
              >
              <p>{{ $t("WareHouse") }}</p>
            </router-link>
            <div class="collapse-icon">
              <b-icon-chevron-down />
            </div>
          </b-list-group-item>
          <b-collapse
            id="collapse-13"
            class="mt-2"
            accordion="my-accordion"
          >
            <b-list-group-item
              v-if="
                getLoggedInUser.role !== 'OnlineSales' &&
                  getLoggedInUser.role !== 'Designer' &&
                  getLoggedInUser.role !== 'StockManagerMaterialAndProduct' &&
                  getLoggedInUser.role !== 'StockManagerMaterialVariant' &&
                  getLoggedInUser.role !== 'SalesPerson' &&
                  getLoggedInUser.role !== 'StockManagerWarehousProductions' &&
                  getLoggedInUser.role !== 'Receptionist' &&
                  getLoggedInUser.role !== 'StockManager'"
              button
            >
              <router-link
                to="/location"
                @click.native="clickLink"
              >


                <p>{{ $t("Location") }}</p>
              </router-link>
            </b-list-group-item>
            <b-list-group-item
              v-if="
                getLoggedInUser.role !== 'Deliverer' &&
                  getLoggedInUser.role !== 'TeamLeader' &&
                  getLoggedInUser.role !== 'Sewer' &&
                  getLoggedInUser.role !== 'Trainee' &&
                  getLoggedInUser.role !== 'PatternMaker' &&
                  getLoggedInUser.role !== 'StockManagerMaterialAndProduct' &&
                  getLoggedInUser.role !== 'StockManagerMaterialVariant' &&
                  getLoggedInUser.role !== 'SalesPerson' && client != 'DrenushaXharra'
              "
              button
            >
              <router-link
                to="/order-item-location"
                @click.native="clickLink"
              >
                <p>{{ $t("OrderItemLocation") }}</p>
              </router-link>
            </b-list-group-item>
            <b-list-group-item
              v-if="
                getLoggedInUser.role == 'SalesPerson' ||
                  getLoggedInUser.role == 'Receptionist' ||
                  getLoggedInUser.role == 'TeamLeader' ||
                  getLoggedInUser.role == 'Manager' ||
                  getLoggedInUser.role == 'CEO' ||
                  getLoggedInUser.role != 'StockManagerMaterialAndProduct' ||
                  getLoggedInUser.role != 'StockManagerMaterialVariant' ||
                  getLoggedInUser.role == 'Admin' &&
                  getLoggedInUser.role !== 'StockManagerWarehousProductions' &&
                  client != 'DrenushaXharra'

              "
              button
            >
              <router-link
                to="/product-in-stock-location"
                @click.native="clickLink"
              >
                <p class="card-text">
                  {{ $t("ProductOverviewInStock") }}
                </p>
              </router-link>
            </b-list-group-item>
            <b-list-group-item
              v-if="getLoggedInUser.role !== 'OnlineSales' &&
                getLoggedInUser.role !== 'Designer' &&
                getLoggedInUser.role !== 'StockManagerWarehousProductions' &&
                getLoggedInUser.role !== 'StockManager' &&
                getLoggedInUser.role !== 'SalesPerson' && getLoggedInUser.role !== 'Receptionist' && client != 'DrenushaXharra'"
              button
            >
              <router-link
                to="/material-variant-location"
                @click.native="clickLink"
              >
                <p class="card-text">
                  {{ $t("MaterialVariantLocation") }}
                </p>
              </router-link>
            </b-list-group-item>
            <b-list-group-item
              v-if="
                getLoggedInUser.role !== 'OnlineSales' &&
                  getLoggedInUser.role !== 'Designer' &&
                  getLoggedInUser.role !== 'StockManagerMaterialAndProduct' &&
                  getLoggedInUser.role !== 'StockManagerMaterialVariant' &&
                  getLoggedInUser.role !== 'SalesPerson' &&
                  getLoggedInUser.role !== 'StockManagerWarehousProductions' &&
                  getLoggedInUser.role !== 'Receptionist' &&
                  getLoggedInUser.role !== 'StockManager'"
              button
            >
              <router-link
                to="/filiales"
                @click.native="clickLink"
              >


                <p>{{ $t("Store") }}</p>
              </router-link>
            </b-list-group-item>
          </b-collapse>
          <b-list-group-item
            v-if="
              getLoggedInUser.role !== 'Deliverer' &&
                getLoggedInUser.role !== 'TeamLeader' &&
                getLoggedInUser.role !== 'Sewer' &&
                getLoggedInUser.role !== 'Trainee' &&
                getLoggedInUser.role !== 'PatternMaker' &&
                getLoggedInUser.role !== 'StockManager' &&
                getLoggedInUser.role !== 'OnlineSales' &&
                getLoggedInUser.role !== 'Designer' &&
                getLoggedInUser.role !== 'SalesPerson' &&
                getLoggedInUser.role !== 'Receptionist' &&
                getLoggedInUser.role !== 'Accountant' &&
                getLoggedInUser.role !== 'Security' &&
                getLoggedInUser.role !== 'Chauffer' &&
                getLoggedInUser.role !== 'CleaningPerson' &&
                getLoggedInUser.role !== 'HR' &&
                getLoggedInUser.role !== 'StockManagerWarehousProductions' &&
                getLoggedInUser.role !== 'HRFinance' &&
                getLoggedInUser.role !== 'Deliverer' &&
                getLoggedInUser.role !== 'TeamLeaderPattern'
            "
            button
          >
            <router-link
              to="/suppliers"
              @click.native="clickLink"
            >
              <img
                src="@/assets/images/supplier.svg"
                width="30px"
                alt="test"
              >

              <p>{{ $t("Suppliers") }}</p>
            </router-link>
          </b-list-group-item>

          <b-list-group-item
            v-if="
              getLoggedInUser.role == 'hellotest' &&
                getLoggedInUser.role !== 'StockManagerMaterialAndProduct' &&
                getLoggedInUser.role !== 'StockManagerMaterialVariant'
            "
            button
          >
            <router-link
              to="/history-production"
              @click.native="clickLink"
            >
              <img
                src="@/assets/images/8. History of Production (1).svg"
                width="30px"
                alt="test"
              >
              <p>{{ $t("HistoryOfProduction") }}</p>
            </router-link>
          </b-list-group-item>
          <b-list-group-item
            v-if="
              getLoggedInUser.role == 'Admin' || getLoggedInUser.role == 'Manager' || getLoggedInUser.role == 'Receptionist' || getLoggedInUser.role == 'CEO' || (getLoggedInUser.role == 'SalesPerson' && client == 'DrenushaXharra')
            "
            v-b-toggle.collapse-5
            button
          >
            <router-link
              to="/nowhere"
              class="disabled-link"
              :class="{ 'active' : $route.path === '/clients' || $route.path === '/campaign'}"
            >
              <img
                src="@/assets/images/clients.svg"
                width="30px"
                alt="test"
              >
              <p>{{ $t("Clients") }}</p>
            </router-link>
            <div class="collapse-icon">
              <b-icon-chevron-down />
            </div>
          </b-list-group-item>
          <b-collapse
            id="collapse-5"
            class="mt-2"
            accordion="my-accordion"
          >
            <b-list-group-item
              v-if="
                getLoggedInUser.role == 'Admin' || getLoggedInUser.role == 'Manager' || getLoggedInUser.role == 'Receptionist' || getLoggedInUser.role == 'CEO' || (getLoggedInUser.role == 'SalesPerson' && client == 'DrenushaXharra')
              "
              button
            >
              <router-link
                to="/clients"
                @click.native="clickLink"
              >
                <p>{{ $t("Clients") }}</p>
              </router-link>
            </b-list-group-item>
            <b-list-group-item
              v-if="
                getLoggedInUser.role == 'Admin' || getLoggedInUser.role == 'Manager' || getLoggedInUser.role == 'Receptionist' || getLoggedInUser.role == 'CEO' || (getLoggedInUser.role == 'SalesPerson' && client == 'DrenushaXharra')
              "
              button
            >
              <router-link
                to="/campaign"
                @click.native="clickLink"
              >
                <p>{{ $t("Campaign") }}</p>
              </router-link>
            </b-list-group-item>
          </b-collapse>
          <b-list-group-item
            v-if="
              getLoggedInUser.role !== 'Deliverer' &&
                getLoggedInUser.role !== 'TeamLeader' &&
                getLoggedInUser.role !== 'Sewer' &&
                getLoggedInUser.role !== 'Trainee' &&
                getLoggedInUser.role !== 'PatternMaker' &&
                getLoggedInUser.role !== 'StockManager' &&
                getLoggedInUser.role !== 'OnlineSales' &&
                getLoggedInUser.role !== 'Designer' &&
                getLoggedInUser.role !== 'SalesPerson' &&
                getLoggedInUser.role !== 'Receptionist' &&
                getLoggedInUser.role !== 'Accountant' &&
                getLoggedInUser.role !== 'Security' &&
                getLoggedInUser.role !== 'Chauffer' &&
                getLoggedInUser.role !== 'CleaningPerson' &&
                getLoggedInUser.role !== 'HR' &&
                getLoggedInUser.role !== 'HRFinance' &&
                getLoggedInUser.role !== 'StockManagerMaterialAndProduct' &&
                getLoggedInUser.role !== 'StockManagerMaterialVariant' &&
                getLoggedInUser.role !== 'StockManagerWarehousProductions' &&
                getLoggedInUser.role !== 'Deliverer' &&
                getLoggedInUser.role !== 'TeamLeaderPattern'
            "
            button
          >
            <router-link
              to="/analytics"
              @click.native="clickLink"
            >
              <img
                src="@/assets/images/analytics.png"
                width="30px"
                alt="test"
              >

              <p>{{ $t("Analytics") }}</p>
            </router-link>
          </b-list-group-item>
        </b-list-group>
      </div>
      <b-list-group class="settings">
        <b-list-group-item
          v-b-modal.modal-personal-info
        >
          <router-link
            to="/nowhere"
            class="disabled-link"
          >
            <img
              src="@/assets/images/settings.svg"
              width="30px"
              alt="test"
            >
            <p
              style="margin-left: 10px; font-size: 16px; font-weight: 400;"
            >{{ $t("Settings") }}</p>
          </router-link>
        </b-list-group-item>
      </b-list-group>
    </div>
    <Personal />

  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { client } from '@/domainConfig'
import Personal from '@/components/personalInfo.vue'


export default {
  components: {
    Personal,
  },

  data() {
    return {
      isHovered: false,
      width: 0,
      client: client.clientName,
    };
  },
  computed: {
    ...mapGetters(['getLoggedInUser', 'getIsSidebarHidden']),
  },
  watch: {
    width(value) {
      // console.log(value);
      if (value <= 1190) {
        this.changeVisibilityOfSidebar(true);
      } else {
        this.changeVisibilityOfSidebar(false);
      }
    },
  },
  created() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  },

  methods: {
    ...mapActions([
      'resetDressColors',
      'resetSizes',
      'resetProductsByArticleNumber',
      'resetCreatedOrdersByOrderNumber',
      'changeVisibilityOfSidebar',
      'resetCreatedOrdersByOrderNumberMaterials',
      'resetInternalMoving',
      'resetReadOrderItems',
      'resetLocationMoving',
      'resetTotalStockGroupedByLocation',
      'resetCreatedOrdersByOrderNumberWindow',
    ]),
    resetTotalStock() {
      this.resetTotalStockGroupedByLocation()
    },
    resetInternal() {
      this.resetInternalMoving()
      this.resetReadOrderItems()
      this.resetLocationMoving()
    },
    setHovered(value) {
      this.isHovered = value;
    },
    handleResize() {
      this.width = window.innerWidth;
    },
    clickLink() {
      if (this.width <= 1190) {
        this.changeVisibilityOfSidebar(true);
      }
    },
    reset() {
      this.resetDressColors();
      this.resetSizes();
      this.resetProductsByArticleNumber();
      this.resetCreatedOrdersByOrderNumberMaterials()
      this.resetCreatedOrdersByOrderNumberWindow()
    },
  },
};
</script>

<style scoped>

.sidebar .list-group-item a.active {
  background-color: rgba(255, 39, 79, 0.1) !important;
  border-left: 3px solid #ff274f !important;
}
.sidebar:hover .image1 {
  display: none !important;
}
.sidebar:hover .image2 {
  display: block !important;
}
.sidebar .image2{
  display:none !important;
}
.sidebar .list-group .collapse .list-group-item a.router-link-active {
    color: #242F6E;
    background-color:rgba(255, 39, 79, 0.1) !important;
    display: none;
  border-left: 3px solid #ff274f !important;

}
.sidebar .list-group .list-group-item a.router-link-exact-active {
    color: #242F6E;
    background-color: rgba(255, 39, 79, 0.1) !important;
    border-left: 3px solid #ff274f !important;
}
.sidebar:hover .collapse .list-group-item a.router-link-active{
    display: flex;
    color: #242F6E;
    background-color: #e8e8e8;
  border-left: 3px solid #ff274f !important;

}
.sidebar .list-group-item .collapse-icon {
    position: absolute;
    right: 25px;
    top: 50%;
    transform: translatey(-50%);
    transition: ease all 0.4s;
    display: none;
}
.sidebar:hover .collapse-icon{
  display: block;
}
.sidebar .list-group .list-group-item p {
  display: none;
}

.sidebar .list-group-item a.active {
  background-color: #e8e8e8;
}

.allButtons{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%
}

.settings{
  border-top: 1px solid #DDE0E5;
  border-radius: 0px;
}

.sidebar {
  display: block !important;
  overflow: hidden;
  width: 5% !important;
  height: 100%;
  transition: width 0.3s ease-in-out;
}

.sidebar:hover .list-group-item p {
  display: block;
  width: 100%;
}

.sidebar:hover {
  width: 17% !important;
  overflow: auto !important;
  height: 100vh;
  transition: width 0.16s; /* Disable transition when hovered */
}
.hide {
  display: none !important;
}
button a p{
  margin-left: 10px;
  font-size: 16px;
  font-weight: 400;
}
.show {
  display: block !important;
}

.side-enter-active,
.side-leave-active {
  transition: transform 0.2s ease;
}

.side-enter,
.side-leave-to {
  transform: translateX(-100%);
  transition: all 150ms ease-in 0s;
}

/* .router-link-active,
  .router-link-exact-active {
    background-color: red;
    color: #fff !important;
  } */

@media screen and (min-width: 1500px) and (max-width: 1700px) {
  .sidebar .list-group .list-group-item a {
    padding: 0.9rem 1.5rem;
  }
}

@media screen and (min-width: 1300px) and (max-width: 1500px) {
  .sidebar .list-group .list-group-item a {
    padding: 0.9rem 1.2rem;
  }
}

@media screen and (max-width: 1300px){
  .sidebar {
    display: none;
    width: 6% !important;
  }
  .sidebar .list-group .list-group-item a {
    padding: 0.9rem 1.2rem;
  }
}

@media only screen and (max-width: 1200px) {
  .sidebar {
    display: none;
    width: 7% !important;
  }
}

@media only screen and (max-width: 1190px) {
  .sidebar .list-group .collapse .list-group-item a.router-link-active {
    color: #242F6E;
    background-color: #e8e8e8;
    display: block;
}
.sidebar:hover .collapse .list-group-item a.router-link-active{
/* display: none; */
color: #242F6E;
    background-color: #e8e8e8;
}
.sidebar .list-group-item .collapse-icon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translatey(-50%);
    transition: ease all 0.3s;
    display: block;
}
.sidebar:hover .collapse-icon{
  display: none;
}
.sidebar .list-group .list-group-item p {
  display: block;

}

.sidebar {
  display: block !important;
  overflow: auto;
  width: 25% !important;
  transition: width 0.3s ease;
}

.sidebar:hover .list-group-item p {
  display: block;
  width: 100%;
}

.sidebar:hover {
  width: 25% !important;
  height: 100vh;
  display: none;
}
  .sidebar {
    display: block !important;
  }
}

/* @media screen and (max-width: 767px) {
  .sidebar {
    display: none;
  }
} */
</style>
